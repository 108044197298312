/* eslint-disable react/no-children-prop */
import { Suspense, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SectionLayout, Spinner } from '@breezeai-frontend/cargo-ui';

import { PlatformErrorBoundary } from '../../../router/PlatformErrorBoundary';
import { CertificateFlowForm } from './CertificateFlowForm/CertificateFlowForm.tsx';
import { useFormLogic } from './useFormLogic';

const FormPlaceholder = () => {
  return (
    <div className="flex w-full h-full bg-white rounded-3xl p-3">
      <Spinner className="self-center" />
    </div>
  );
};

export function CertificateFlowPage() {
  const { isUpdate } = useFormLogic();

  const { certificateId } = useFormLogic();
  const [formKey, setFormKey] = useState(0);

  const title =
    isUpdate && certificateId
      ? `Certificate ${certificateId}`
      : 'Get a Certificate';

  const resetForm = () => {
    setFormKey((prev) => prev + 1);
  };

  return (
    <PlatformErrorBoundary>
      <SectionLayout
        title={
          <div className="flex flex-row items-center gap-2">
            <Link to="/certificates">
              <FaChevronLeft />
            </Link>

            {title}
          </div>
        }
      >
        <Suspense fallback={<FormPlaceholder />}>
          <CertificateFlowForm
            key={formKey}
            // Remount the form when the key changes to reset the form
            remountForm={resetForm}
          />
        </Suspense>
      </SectionLayout>
    </PlatformErrorBoundary>
  );
}
