import { type HTMLAttributes, type ReactNode } from 'react';

import { Typography } from '../../components/Typography/Typography';

interface SectionLayoutProps
  extends Omit<
    HTMLAttributes<HTMLDivElement>,
    'title' | 'actions' | 'children'
  > {
  title?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
}

export function SectionLayout({
  title,
  actions,
  children,
  ...props
}: SectionLayoutProps) {
  const isDisplayTitle = title || actions;
  return (
    <>
      {isDisplayTitle && (
        <div className="sticky pt-10 pb-10 top-0 flex-row flex justify-between w-full z-20 bg-gradient-to-b from-surfaces-secondary from-60% to-transparent gap-x-3">
          {title && (
            <Typography
              level="h2"
              customStyles="w-full whitespace-nowrap"
              role="heading"
              data-testid="page-title"
            >
              {title}
            </Typography>
          )}
          <div className="flex items-center gap-4 justify-self-end">
            {actions}
          </div>
        </div>
      )}
      <div {...props} className="flex w-full h-full">
        {children}
      </div>
    </>
  );
}
